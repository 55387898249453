
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import { StoreState } from "@/store";

export default Vue.extend({
  name: "ProfileDropdown",

  computed: {
    ...mapGetters({
      token: "parsedToken",
      isLoggedIn: "isLoggedIn",
      hasPermission: "hasPermission"
    }),
    ...mapState({
      user: (state: any) => (state as StoreState).user.user
    })
  },

  methods: {
    logOut() {
      this.$store.dispatch("logout");
      window.location.replace(process.env.VUE_APP_PLAZA_URL + "/?logout=true");
    },
    nothing() {
      return;
    }
  }
});
