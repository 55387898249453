import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store";
import { authenticate } from "@/api";
import { requestAuthRedirect } from "@/utils/authUtils";

Vue.use(VueRouter);

const epinAccessAllowed = (epin: string): boolean => {
  if (!store.getters.parsedToken?.plaza_scopes?.is_epd_user) {
    return true;
  }
  return store.getters.parsedToken?.plaza_scopes?.patient_epin === epin;
};

export const routes: Array<RouteConfig> = [
  {
    path: "/patients",
    name: "patients",
    component: () =>
      import(
        /* webpackChunkName: "patient-overview" */ "../views/PatientsOverview.vue"
      ),
    meta: {
      permissions: ["prescriptions.create", "prescriptions.submit"]
    }
  },
  {
    path: "/requests",
    name: "requests",
    component: () =>
      import(
        /* webpackChunkName: "request-overview" */ "../views/RequestsOverview.vue"
      ),
    meta: {
      permissions: [
        "prescriptions.create",
        "prescriptions.submit",
        "prescriptions.list"
      ]
    }
  },
  {
    path: "/patient/new",
    name: "patient-create",
    component: () =>
      import(
        /* webpackChunkName: "patient-create" */ "../views/PatientView.vue"
      ),
    meta: {
      permissions: ["prescriptions.create", "prescriptions.submit"]
    }
  },
  {
    path: "/patient/:epin",
    name: "patient-dossier",
    component: () =>
      import(
        /* webpackChunkName: "patient-dossier" */ "../views/PatientDossier.vue"
      ),
    meta: {
      permissions: ["prescriptions.create", "prescriptions.submit"],
      epdUserAllowed: true
    },
    beforeEnter: async (to, from, next) => {
      epinAccessAllowed(to.params.epin)
        ? next()
        : next("/no-access/user-scoped");
    }
  },
  {
    path: "/specifier/:artsId",
    name: "specifier-view",
    component: () =>
      import(
        /* webpackChunkName: "specifier-view" */ "../views/SpecifierView.vue"
      )
  },
  {
    path: "/specifier/:artsId/contact-detail/:contactDetailId",
    name: "specifier-contact-detail-view",
    component: () =>
      import(
        /* webpackChunkName: "specifier-view" */ "../views/SpecifierContactDetailView.vue"
      )
  },
  {
    path: "/patient/:epin/requests/:requestId",
    name: "request-details",
    component: () =>
      import(
        /* webpackChunkName: "request-details" */ "../views/RequestDetails.vue"
      ),
    meta: {
      permissions: ["prescriptions.create", "prescriptions.submit"],
      epdUserAllowed: true
    },
    beforeEnter: async (to, from, next) => {
      epinAccessAllowed(to.params.epin)
        ? next()
        : next("/no-access/user-scoped");
    }
  },
  {
    path: "/patient/:epin/edit",
    name: "patient-edit",
    component: () =>
      import(/* webpackChunkName: "patient-edit" */ "../views/PatientView.vue"),
    meta: {
      permissions: ["prescriptions.create", "prescriptions.submit"],
      epdUserAllowed: true
    },
    beforeEnter: async (to, from, next) => {
      epinAccessAllowed(to.params.epin)
        ? next()
        : next("/no-access/user-scoped");
    }
  },
  {
    path: "/request/:requestId/step/:stepId",
    name: "request",
    component: () =>
      import(/* webpackChunkName: "request-view" */ "../views/RequestView.vue"),
    beforeEnter: async (to, from, next) => {
      if (!store.getters["request/hasRequestInStore"] && to.params.requestId) {
        const requestId = to.params.requestId;
        await store.dispatch("request/setRequestConfig", requestId);
      }

      return store.getters["request/hasRequestInStore"]
        ? next()
        : next("/patients");
    },
    meta: {
      permissions: ["prescriptions.create", "prescriptions.submit"],
      epdUserAllowed: true
    }
  },
  {
    path: "/request-complete/:requestId",
    name: "request-complete",
    component: () =>
      import(
        /* webpackChunkName: "request-complete" */ "../views/RequestComplete.vue"
      ),
    meta: {
      epdUserAllowed: true
    }
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(
        /* webpackChunkName: "specifier-view" */ "../views/ProfileView.vue"
      )
  },
  {
    path: "/no-access/:reason",
    name: "no-access",
    component: () =>
      import(/* webpackChunkName: "no-access" */ "../views/NoAccess.vue"),
    meta: {
      epdUserAllowed: true
    }
  },
  { path: "*", redirect: "/requests" }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

const hasPermissionMatch = (neededPermissions: string[]): boolean => {
  if (!store.getters.isLoggedIn) return false;
  const userPermissions = store.getters.permissions;
  return userPermissions.some((p: string) => neededPermissions.includes(p));
};

router.beforeEach(async (to, from, next) => {
  const access_token = new URLSearchParams(location.search).get("access_token");

  if (access_token) {
    await authenticate(access_token);

    if (!store.getters.isEpdUser) {
      window.location.replace(window.location.origin + "/patients");
    } else {
      window.location.replace(
        `${window.location.origin}/patient/${store.getters.scopedPatient}`
      );
    }
  }

  // EPD user trying to access a disabled route
  if (
    store.getters.isLoggedIn &&
    store.getters.isEpdUser &&
    !to.meta?.epdUserAllowed
  ) {
    next("/no-access/user-scoped");
  }

  // If route has no needed permissions, continue
  else if (!to.matched.some((record) => record.meta.permissions)) {
    next();
  }

  // If user is not logged in and doesn't have a token, redirect to plaza
  else if (!store.getters.isLoggedIn && !access_token) {
    if (window.location.href.includes("no-access")) {
      requestAuthRedirect(process.env.VUE_APP_BASE_URL);
    }
    requestAuthRedirect(window.location.href);
  }

  // User is logged in, make sure token is valid and has the right permissions
  else if (store.getters.isLoggedIn) {
    if (!store.getters.isTokenValid) {
      next("/no-access/token-expired");
    } else if (
      !hasPermissionMatch(["prescriptions.create", "prescriptions.submit"])
    ) {
      next("/no-access/missing-permissions");
    }
    // If route has needed permissions, make sure that user has matching rights
    else if (
      to.matched.some(({ meta }) => meta.permissions) &&
      hasPermissionMatch(to.meta?.permissions)
    ) {
      next();
    } else {
      next("/no-access");
    }
  }
});

export default router;
